import apiService from '@/services/shared/http/apiService'

export default class AuthService extends apiService {
  forgetPassword(data) {
    return this.POST('forget-password', data)
  }

  resetPassword(data) {
    return this.POST('reset-password', data)
  }

  login(data) {
    return this.POST('login', data)
  }

  logout() {
    return this.POST('logout')
  }

  verify(params) {
    return this.POST(`email/verify?model=${params.model}&id=${params.id}`)
  }
}
