<template>
  <div class="auth-container">
    <div class="auth-hero">
      <img :src="heroImageSrc" class="auth-hero-image" />
    </div>
    <div class="auth-content">
      <div class="auth-logo">
        <img :src="logoSrc" class="logo" />
        <span class="logo-badge">
          <small>{{ $t('for') }}&nbsp;</small>
          <strong>{{ $t('business') }}</strong>
        </span>
      </div>
      <p>
        {{ $t('claim_profile_message') }}
      </p>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="validationForm">
          <b-form-group :label="$t('Email')" label-for="forgot-password-email">
            <validation-provider #default="{ errors }" :name="$t('Email')" rules="required|email">
              <b-form-input
                id="forgot-password-email"
                v-model="email"
                disabled="disabled"
                :state="errors.length > 0 ? false : null"
                name="forgot-password-email"
                placeholder="Stephanie@toddl.co"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group :label="$t('Language')" label-for="lang">
            <div>
              <span :class="'label-lang ' + (lang === 'es' ? 'active' : '')" @click="setLang('es')"
                >Castellano</span
              >
              <span :class="'label-lang ' + (lang === 'en' ? 'active' : '')" @click="setLang('en')"
                >English</span
              >
            </div>
          </b-form-group>
          <b-button type="submit" variant="primary" block :disabled="isLoading">
            {{ $t('Claim your profile') }}
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'

import heroImageSrc from '@/assets/images/provider/login-background.jpg'
import logoSrc from '@/assets/images/logo/toddl.co_logo white.svg'
import { required, email } from '@/libs/validations'
import AuthService from '@/services/AuthService'

export default {
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: this.$route.query.email,
      isLoading: false,
      lang: this.$i18n.locale,
      AuthService: new AuthService(),
      heroImageSrc,
      logoSrc,
    }
  },
  mounted() {
    if (this.$route.query?.lang === 'en' || this.$route.query?.lang === 'es') {
      localStorage.setItem('locale', this.$route.query.lang)
      this.$store.commit('appConfig/SET_LANG', this.$route.query.lang)
      this.$i18n.locale = this.$route.query.lang
    }
    if (this.$route.query?.email) {
      this.$posthog.setPersonProperties({
        email: this.$route.query.email,
        isClaimedAccount: true,
      })
    }
  },
  methods: {
    setLang(str) {
      this.lang = str
      this.$i18n.locale = str
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.AuthService.forgetPassword({ email: this.email, claim: true })
            .then(() => {
              this.$posthog.capture('Claim profile', {
                email: this.email,
              })
              this.isLoading = false

              this.$router.replace({
                path: '/claim/success',
                query: {
                  email: this.email,
                },
              })
            })
            .catch(err => {
              console.error(err)
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.auth-container {
  display: grid;
  gap: 0;
  grid-template-columns: minmax(0, 0%) minmax(0, 1fr);
  height: 100vh;
}

.auth-hero {
  position: relative;
  height: 100%;
}

.auth-hero-image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.auth-content {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #0e1a1a;
}

.auth-logo {
  display: flex;
  flex-direction: row;
  height: fit-content;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.logo {
  width: 100%;
}

.logo-badge {
  font-size: 26px;
  display: inline-block;
  color: #4b64a5;
  margin-bottom: 8px;
}

.logo-badge strong {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .auth-container {
    grid-template-columns: minmax(0, 66%) minmax(0, 1fr);
  }
}
</style>
